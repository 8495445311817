@import 'material.scss';

button:disabled {
  opacity: 0.50;
  cursor: not-allowed;
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}


.mdc-tooltip__surface {
  font-size: 14px !important;
  background-color: #fff !important;
  color: #000 !important;
  @apply ring-2 ring-black ring-opacity-5 shadow;
  border-radius: 0 !important;
  @apply whitespace-pre-wrap;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

* {
  outline: none !important;
}

.filter-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.filter-gray-600 {
  filter: invert(33%) sepia(5%) saturate(1773%) hue-rotate(176deg) brightness(91%) contrast(88%);
}

.filter-gray-700 {
  filter: invert(22%) sepia(18%) saturate(762%) hue-rotate(178deg) brightness(95%) contrast(87%);
}

// redo me
.filter-primary-600 {
  filter: invert(19%) sepia(35%) saturate(5636%) hue-rotate(214deg) brightness(91%) contrast(119%);
}

.filter-secondary-600 {
  filter: invert(70%) sepia(67%) saturate(6800%) hue-rotate(25deg) brightness(108%) contrast(97%);
}

.filter-primary-800 {
  filter: invert(8%) sepia(26%) saturate(4386%) hue-rotate(234deg) brightness(129%) contrast(127%);
}

.filter-green-600 {
  filter: invert(68%) sepia(29%) saturate(449%) hue-rotate(91deg) brightness(93%) contrast(89%);
}

.filter-green-900 {
  filter: invert(20%) sepia(72%) saturate(465%) hue-rotate(114deg) brightness(105%) contrast(100%);
}

.filter-danger-600 {
  // loss 4.3
  filter: invert(45%) sepia(43%) saturate(4862%) hue-rotate(333deg) brightness(94%) contrast(100%);
}


$font-list: (
  // Chinese simplified
        NotoSansSC: (
                VF: 100 1000,
        ),
  // japanese
        MPLUS1p: (
                Thin: 100,
                Light: 300,
                Regular: 400,
                Medium: 500,
                Bold: 700,
                ExtraBold: 800,
                Black: 900,
        ),
  // Arabic
        Cairo: (
                ExtraLight: 200,
                Light: 300,
                Regular: 400,
                Medium: 500,
                SemiBold: 600,
                Bold: 700,
                ExtraBold: 800,
                Black: 900,
        ),
  // DK, czech + regular aschii chars
        Inter: (
                Thin: 100,
                ExtraLight: 200,
                Light: 300,
                Regular: 400,
                Medium: 500,
                SemiBold: 600,
                Bold: 700,
                ExtraBold: 800,
                Black: 900,
        ),
);

@each $font-name, $types in $font-list {
  @each $type-name, $type-weight in $types {
    @font-face {
      font-family: $font-name;
      font-style: normal;
      font-weight: $type-weight;
      font-display: swap;
      src: local(''),
      url('./assets/fonts/' + $font-name + '/' + $font-name + '-' + $type-name  + '.ttf') format('truetype');
    }
  }
}

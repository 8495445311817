@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="checkbox"]:disabled {
    background-color: #6e6e76 !important;
}

.mat-select-disabled, input:disabled, textarea:disabled {
    @apply bg-gray-50 text-gray-500 !important;
}

.h-min-content {
    height: min-content;
}

@screen sm {
    .mat-mdc-dialog-surface {
        @apply w-full p-6;
    }
}
.mat-mdc-dialog-surface {
    @apply bg-white px-4 pt-5 pb-4 shadow transform border-none transition-all;
}

/* Make popup that are too high scrollable */
mat-dialog-container {
    max-height: 100vh !important;
    @apply overflow-auto !important;
}

.ws-base-button {
    @apply ws-focusable;
    @apply inline-flex items-center justify-center border border-gray-300 font-medium shadow-sm focus:outline-none transition duration-150 ease-in-out;
}

.ws-button-small {
    @apply ws-base-button py-1 px-2 text-xs;
}

.ws-button {
    @apply ws-base-button py-2 px-4 text-sm;
}

.ws-button-only-icon {
    @apply ws-base-button py-2 px-4 text-sm;
}

.ws-button-small-only-icon {
    @apply ws-base-button py-1 px-2 text-xs;
}

.ws-button-large {
    @apply ws-base-button py-2.5 px-5 text-base;
}

.ws-button:not(:disabled) {
    @apply hover:opacity-90;
}

.ws-button-large:not(:disabled) {
    @apply hover:opacity-90;
}

.ws-button-small img {
    @apply -ml-0.5 mr-1 h-2.5 w-2.5;
}

.ws-button img {
    @apply -ml-1 mr-2 h-5 w-5;
}

.ws-button-large img {
    @apply -ml-1.5 mr-2.5 h-7 w-7;
}

.ws-primary {
    @apply text-white bg-primary-600;
}

.ws-secondary {
    @apply text-gray-600 bg-white hover:bg-gray-50 active:bg-white;
}

.ws-third {
    @apply text-white bg-secondary-600;
}

.ws-cancel {
    @apply border border-gray-300 text-gray-700 hover:text-gray-500 focus:border-gray-500 active:bg-gray-50 active:text-gray-800;
}

.ws-link {
    @apply border-0 shadow-none text-gray-700 active:text-gray-800;
}

.ws-a-link {
    @apply text-primary-600 hover:text-primary-800;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600;
}

.ws-base {
    @apply focus:outline-none focus:ring-2 focus:ring-primary-600;
}

.ws-focusable:not(.ws-not-focusable) {
    @apply ws-base;
    @apply focus:ring-offset-2;
}

.ws-focusable-close:not(.ws-not-focusable) {
    @apply ws-base;
    @apply focus:ring-offset-0;
}

.ws-danger {
    @apply text-white bg-danger-600 hover:bg-danger-500 active:bg-danger-600;
}

.ws-label {
    @apply block text-sm font-medium leading-5 text-gray-700;
}

.ws-label-flex {
    @apply flex text-sm font-medium leading-5 text-gray-700;
}

.ws-input {
    @apply ws-base-input mt-2 py-2 px-3;
}

.ws-disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
}

.ws-checkbox.ng-invalid.ng-touched,
mat-select.ng-invalid.ng-touched,
.ws-base-input.ng-invalid.ng-touched {
    @apply ring-danger-600 border-danger-600 !important;
}

.ws-base-input {
    @apply block w-full border border-gray-300 shadow-sm focus:ring-primary-600 focus:border-primary-600 transition duration-150 ease-in-out sm:text-sm sm:leading-5;
}

/** Increases contrast on reporting pages to comply with WCAG/WACA **/
#main-content .ws-base-input {
    @apply border-gray-500;
}

#main-content .ws-base-button {
    @apply border-gray-500;
}

#main-content .ws-checkbox {
    @apply border border-gray-500;
}

/** Fix froala lists **/
.fr-view ol,
.fr-wrapper ol,
.fr-view ul,
.fr-wrapper ul {
    padding: unset;
    padding-left: 20px;
    margin: 0;
}

.fr-view ol,
.fr-wrapper ol {
    list-style-type: decimal;
}

.fr-view ul,
.fr-wrapper ul {
    list-style-type: disc;
}

/** Display popup **/
.fr-popup {
    z-index: 9999 !important;
}

/** Center video if no align is chosen and fix alignment **/
.fr-video:not(.fr-fvr):not(.fr-fvl) {
    width: 100%;
}

.fr-video:not(.fr-fvr):not(.fr-fvl) video {
    margin-left: auto;
    margin-right: auto;
}

.fr-video.fr-fvr video {
    margin-left: auto;
}

.fr-video.fr-fvl video {
    margin-right: auto;
}

/** Froala show correct default font */
.fr-box.fr-basic .fr-element.fr-view {
    @apply font-sans;
}

/** Froala default blue link */
.fr-view a,
.fr-wrapper a {
    @apply underline text-primary-600 hover:text-primary-500;
}

/** Froala H1 */
.fr-view h1,
.fr-wrapper h1 {
    font-size: 24px;
}

/** Froala H2 */
.fr-view h2,
.fr-wrapper h2 {
    font-size: 18px;
}

/** Froala H3 */
.fr-view h3,
.fr-wrapper h3 {
    font-size: 14px;
}

/** froala div fix */
.fr-view div {
    max-width: 100%;
}

/** Froala no border-radius container */
.fr-toolbar,
.fr-second-toolbar,
.fr-wrapper {
    @apply rounded-none border-gray-300 !important;
}

/** Custom branding **/
.border-radius-branding-null,
.border-radius-branding-0 {
    border-radius: 0 !important;
}

.border-radius-branding-5 {
    border-radius: 5px !important;
}

.border-radius-branding-8 {
    border-radius: 8px !important;
}

.border-radius-branding-12 {
    border-radius: 12px !important;
}

.border-radius-branding-100 {
    border-radius: 100px !important;
}

/** Mat-menu **/
.mat-mdc-menu-panel {
    @apply mt-2 shadow-lg bg-white focus:outline-none;
    @apply ring-2 shadow-lg ring-black ring-opacity-5 !important;
    @apply rounded-none min-h-0 !important;
}

.mat-mdc-menu-content {
    @apply p-0 !important;
}

.mat-mdc-menu-item {
    @apply min-h-0 h-auto leading-none text-base text-base text-gray-900 !important;
    @apply font-sans px-4 py-2 !important;
}
